import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import { Routes, Route } from "react-router-dom"
import { Suspense } from 'react';
import Loading from './pages/miscellanious/loading'
import Header from './layouts/adminLayout/index'
import { PATH } from './constants/path';

const Login = React.lazy(() => import('./pages/login/login'))
const Home = React.lazy(() => import('./pages/home/index'))

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route element={<Header />}>
            <Route path={PATH.HOME} element={<Home />} />
          </Route>
        </Routes>
      </Suspense>

    </div>
  );
}

export default App;
