import {configureStore,combineReducers} from '@reduxjs/toolkit'
import errorMessageReducer from '../reducer/errorMessageReducer';
const rootReducers=combineReducers({
    ErrorMessageReducer: errorMessageReducer,
})

const store=configureStore({
    reducer:rootReducers,
    middleware:(getdefaultMiddleware)=>getdefaultMiddleware().concat([

    ])
})
export default store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;