
import './loader.scss'

function Index() {
  return (
    <>
          <div className='custom-content-loader'>
            loading...
          </div>
    </>
  )
}

export default Index