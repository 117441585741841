import React, { useEffect, useState, useLayoutEffect } from 'react';
import "./header.scss"
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, PictureOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { Outlet } from 'react-router-dom';
import profileImg from "../../../assets/profile-img.png"
import { useCustomNavigate, useAppSelector } from '../../../base/hooks/hooks';
import { PATH } from '../../../constants/path';
// import { useGetProfileQuery } from '../../../services/apiService/profile/profile';
import logo from "../../../assets/cg-logo 1.png"
import logo1 from "../../../assets/logo1.svg"

const { Header, Sider, Content } = Layout;

const App: any = () => {
    const [current, setCurrent] = useState('1');
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useCustomNavigate()
    const fullUrl = window.location.href;
    const urlObject = new URL(fullUrl);
    const dynamicPath = urlObject.pathname;
    const splitValue = dynamicPath.split('=')
    const userType = localStorage.getItem('role_type')
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    // const { data: profileData, isLoading: getProfileApiIsLoading, isSuccess: getProfileApiIsSuccess, isError: getProfileApiIsError, error: getProfileApiError }: any = useGetProfileQuery()
    const { unAuthorized }: any = useAppSelector((state: any) => state.ErrorMessageReducer)

    const doCheckTheUrlBasedOnTheType = (url: any) => {
        //     const superAdminPath = [PATH.USER_UPLOAD]
        //     let adminPaths = [PATH.CHANNEL_PARTNER, PATH.PROJECTS, '/projects/edit-project/', '/projects/add-project', PATH.BANNER, '/banners/edit-banner/', PATH.ADD_BANNER]
        //     if (userType === "superadmin") {
        //         let isInArray: any
        //         if (url.includes(("?"))) {
        //             isInArray = superAdminPath.includes(url.split('?')[0]);
        //         } else {
        //             isInArray = superAdminPath.includes(url.split('=')[0]);
        //         }
        //         if (!isInArray) {
        //             navigate(PATH.USER_UPLOAD)
        //         }
        //     } else if (userType === "admin") {
        //         let isInArray: any
        //         if (url.includes(("?"))) {
        //             isInArray = adminPaths.includes(url.split('?')[0]);
        //         } else {
        //             isInArray = adminPaths.includes(url.split('=')[0]);
        //         }
        //         if (!isInArray) {
        //             navigate(PATH.BANNER)
        //         }
        //     } else {
        //         navigate(PATH.LOGIN)
        //     }
    }

    const getItems = () => {
        if (userType == 'superadmin') {
            return [
                {
                    key: '5',
                    icon: <UserOutlined />,
                    label: 'Upload Users',
                },
                {
                    key: '4',
                    icon: <LogoutOutlined />,
                    label: 'Logout',
                },
            ]
        } else if (userType == 'admin') {
            return [
                {
                    key: '1',
                    icon: <PictureOutlined />,
                    label: 'Banner',
                },
                {
                    key: '2',
                    icon: <AppstoreOutlined />,
                    label: 'Projects'
                },
                {
                    key: '3',
                    icon: <UserOutlined />,
                    label: 'Channel Partner',
                },
                {
                    key: '4',
                    icon: <LogoutOutlined />,
                    label: 'Logout',
                },
            ]

        }
        return []
    }

    const logoutHandler = () => {
        localStorage.clear()
        navigate(PATH.LOGIN)
        window.location.reload();
    }
    // useLayoutEffect(() => {
    //     const token: any = localStorage.getItem('cp-admin-token')
    //     if (!token) {
    //         logoutHandler()
    //     }
    // }, [])

    useEffect(() => {
        switch (splitValue[0]) {
            case PATH.GRE_LIST:
                setCurrent("1");
                break;
        }
    }, [dynamicPath])

    // useEffect(() => {
    //     doCheckTheUrlBasedOnTheType(window.location.pathname)
    //     if (getProfileApiIsError && getProfileApiError?.status == 401) {
    //         logoutHandler()
    //     }
    //     if (unAuthorized) {
    //         logoutHandler()
    //     }
    //     if (profileData&&!unAuthorized) {
    //         localStorage.setItem('role_type', profileData?.data?.userData?.role);
    //     }
    // }, [getProfileApiError, unAuthorized, getProfileApiIsSuccess, window.location.pathname])

    const onClick = (e: any) => {
        setCurrent(e.key);
        switch (e.domEvent.target.textContent) {
            case "Banner":
                navigate(PATH.GRE_LIST)
                break;
            case "Logout":
                logoutHandler()
                break
        }
    }

    return (
        <Layout className='h-100'>
            <Sider trigger={null} collapsed={collapsed}>
                <div className="demo-logo-vertical">
                    <figure>
                        <img src={logo} />
                        <img src={logo1} className='responsive-logo' />
                    </figure>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[current]}
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: '1',
                            icon: <UserOutlined />,
                            label: 'Users List',
                        },
                        {
                            key: '2',
                            icon: <LogoutOutlined />,
                            label: 'Logout',
                        },
                    ]}
                    onClick={onClick}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 0 }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <div className="user-info">
                        <div className="user-name">
                            <p className="name">
                                {/* {profileData?.data?.userData?.name || '-'} */}
                                John
                            </p>
                            <p className="email">
                                John@mailinator.com
                                {/* {profileData?.data?.userData?.email || '-'} */}
                            </p>
                        </div>
                        <figure>
                            <img src={profileImg} />
                        </figure>
                    </div>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 20,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <div className='dashboard-wrapper'>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;